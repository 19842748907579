<template>
  <v-card>
    
      <v-card-title class="justify-space-between mb-0 py-7">
            <span class="text-h5"><strong> All Donors Report</strong></span>
        </v-card-title>
      <!-- <span class="text-h5"><strong> All Donors Report</strong></span> -->
      <!-- <vue-json-to-csv :json-data="csvListData" :labels="csvLabels" :csv-title="fileName">
          <v-btn color="primary"> Export </v-btn>
        </vue-json-to-csv> -->

    <v-row class="ml-2 mr-2 mb-3">
      <!-- Start date template -->
      <v-col cols="12" md="3" >
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY" readonly outlined
              dense v-bind="attrs" v-on="on" hide-details></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_from" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <!-- End data template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY" readonly outlined dense
              v-bind="attrs" v-on="on" hide-details></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_to" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <!-- <v-col cols="12" md="3">
        <v-autocomplete 
              v-model="filter.year"
              :items="Yearitem" 
              outlined dense hide-details
              label="Year">
          </v-autocomplete>
     </v-col>
     <v-col cols="12" md="3">
        <v-autocomplete 
              v-model="filter.month"
              :items="Monthitem" 
              outlined dense hide-details
              label="Month">
          </v-autocomplete>
     </v-col> -->
      <v-col cols="12" md="3" class="pl-0">
        <v-btn color="primary"  class="mr-2" @click="reportExport()"> Export </v-btn>
        <v-btn color="primary" @click="refreshDate()">Reset</v-btn>
        
      </v-col>
      <!-- <v-col cols="12" md="1">
        

      </v-col> -->
    </v-row>
   
    <!-- <v-row class="ml-3 mr-5 pt-3 mb-3"> -->
      <!-- <v-col cols="12" md="3">
         <v-autocomplete 
              v-model="filter.center_name"
              :items="centerName" 
              item-text="center_name"
              outlined dense hide-details
              label="Center Name">
          </v-autocomplete>
      </v-col> -->
      <!-- <v-col cols="12" md="3">
        <v-text-field v-model="filter.name" label="Donor Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete v-model="filter.urban_rural" :items="Urban_Rural" outlined dense hide-details
          label="Urban/ Rural">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete v-model="filter.chapters" :items="BranchesList" item-text="city" outlined dense hide-details
          label="Chapter">
        </v-autocomplete>
      </v-col> -->
      <!-- <v-col cols="12" md="3">
      <v-text-field v-model="invoice_no" label="utillization" outlined dense hide-details readonly ></v-text-field>
     </v-col> -->


      <!-- <v-col cols="12" md="2">
        <v-btn color="primary" class="mr-8" @click="reportSearch()"> Search</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" class="mr-8" @click="reportview()">Refresh</v-btn>
      </v-col>
    </v-row> -->

    <!-- <v-data-table :headers="headers" :items="donorReport" :items-per-page="10" fixed-header height="400"
      class="table-rounded">
      <template #[`item.operate`]="{}">
        <v-btn color="primary" class="mr-3" @click="aa()"> Print </v-btn>
       
      </template>
    </v-data-table> -->

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import DonorReport from '@/service/Reports.service'
import FileSaver from 'file-saver'
import CenterService from '@/service/Branches.service'
import VueJsonToCsv from 'vue-json-to-csv'
import { api } from '@/config/config'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      Urban_Rural: ['Urban', 'Rural'],
      BranchesList: [{ city: "" }],
      path: [],
      Yearitem: ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      Monthitem: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aa: '',
      menu: false,
      menu1: false,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      donorReport: [],
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      filter: {
        name: '',
        urban_rural: '',
        chapters: '',
        date_from: '',
        date_to: ''
      },

      csvLabels: {
        date: { title: 'Date' },
        donor_name: { title: 'Donor Name' },
        age: { title: 'Age' },
        sex: { title: 'Gender' },
        phone_no: { title: 'Contact No' },
        address: { title: 'Address' },
        consented_by: { title: 'Consented By' },
        death_to_prevent_time_os: { title: 'D to P(OS)' },
        death_to_prevent_time_od: { title: 'D to P(OD)' },
        chapters: { title: 'Chapter' },
        address: { title: 'Address City' },
        urban_rural: { title: 'Urban/Rural' },
        no_of_tissue: { title: 'No. of Tissues' },
        bill_to_os: { title: 'Utilization(OS)' },
        bill_to_od: { title: 'Utilization(OD)' },

      },
      fileName: 'USER-LIST-' + moment().valueOf(),
      csvListData: [],
      headers: [
        { text: 'Date', value: 'date', width: '150px' },
        { text: 'Donor Name', value: 'donor_name', width: '150px' },
        { text: 'Age', value: 'age', width: '150px' },
        { text: 'Gender', value: 'sex', width: '150px' },
        { text: 'Contact No', value: 'phone_no', width: '150px' },
        { text: 'Address', value: 'address', width: '150px' },
        { text: 'Consented By', value: 'consented_by', width: '150px' },
        { text: 'D to P (OS)', value: 'death_to_prevent_time_os', width: '150px' },
        { text: 'D to P (OD)', value: 'death_to_prevent_time_od', width: '150px' },
        { text: 'Chapter', value: 'chapters', width: '150px' },
        { text: 'Address City', value: 'address', width: '150px' },
        { text: 'Urban/Rural', value: 'urban_rural', width: '150px' },
        { text: 'No. of Tissues', value: 'no_of_tissue', width: '150px' },
        { text: 'Utilization(OS)', value: 'bill_to_os', width: '150px' },
        { text: 'Utilization(OD)', value: 'bill_to_od', width: '150px' },

        // { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        // { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
        // { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  computed: {
    dateFormateTo () {
        return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
      }, 
    
    dateFormateFrom () {
        return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
      }, 
    },
  watch: {
    options: {
      handler() {
        this.reportview()
        this.GetCenter()
      },
      deep: true,
    },
  },

  async mounted() {
    this.reportview()
    this.GetCenter()
  },

  methods: {
    async GetCenter() {
      const service = new CenterService()
      let response = await service.BranchesList()
      if (response) {
        this.BranchesList = response.data
      } else {
        this.BranchesList = []
      }
    },

    async reportview() {
      this.listLoading = true
      const service = new DonorReport()
      let response = await service.AllDonorReport()
      if (response) {
        this.donorReport = response
        this.totallist = this.donorReport.length
        this.filter.name = '',
          this.filter.urban_rural = '',
          this.filter.chapters = '',
          this.filter.year = '',
          this.filter.month = ''

      } else {
        this.donorReport = []
        this.totallist = 0
      }
    },


    refreshDate() {
      this.filter.date_from = '';
      this.filter.date_to = '';
    },

    async reportSearch() {
      this.listLoading = true
      const data = {
        name: this.filter.name,
        urban_rural: this.filter.urban_rural,
        chapters: this.filter.chapters,
      }
      try {
        const response = await api.post(`reports/all_donors_reports_searching`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          // console.log("Hello resposnes",response.data.path)

          this.donorReport = response.data.records
          this.totallist = this.donorReport.length
        } else {
          this.donorReport = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
    async reportExport() {
      console.log(this.filter.date_from, this.filter.date_to);
      // return 
      this.listLoading = true
      const data = {
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
      }

      if(this.filter.date_from == '' && this.filter.date_to == ''){
            this.snackbarText = 'Please Fill Date',
              this.snackbar = true
              return;
        }

        
      try {
        const response = await api.post(`reports/all_donors_reports_searching`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.snackbarText = response.data.message,
            this.snackbar = true
          this.path = response.data.path
          // console.log(`https://api.ebsr.in${this.path}`);
          var value = `https://api.ebsr.in${this.path}`
          FileSaver.saveAs(value, 'All donors report');
          this.totallist = this.path.length
        } else {
          this.path = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
